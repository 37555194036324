import React, { useState, useEffect, useRef } from 'react';
import Wrapper from '../../../views/wrapper/Wrapper';
import { Modal, Card, Spin } from 'antd';
import { BookOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { PageLayout } from '../../../components/pageLayout/PageLayout';
import {
  GET,
  POST,
  GET_OPTION_BRANCH_ADMIN,
  GET_SECOND_HAND_PRODUCT_OPTION,
  GET_APH_SECOND_HAND_PRODUCT_LIST,
  POST_APH_SECOND_HAND_DETAIL,
  CANCEL_APH_SECOND_HAND
} from '../../../services/index';
import { auth } from '../../../utils/firebase';
import { useCurrentState } from '../../../utils/hook';
import { convertStrToFormat } from '../../../functions/fn';
import { ButtonTheme } from '../../../components/buttons';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import moment from 'moment';
import { Icon } from '../../../resources/icon';
import { DetailModal } from '../modal/SecondHandDetail';
import { EditRemarkSecondHandModal } from '../modal/EditRemarkSecondHandModal';
import { BuySecondHandReportSearchForm } from '../form';
import { BuySecondHandReportColumn } from '../columns';
import { useGetReportSecondHandExcel } from './export';

const AphBuySecondHandReportScreen = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const pageRef = useRef(1);
  const sizeRef = useRef(10);
  const [totalSize, setTotalSize] = useState(0);
  const [, setTotalPage] = useState(0);
  const [branchState, setBranchState] = useState(null);
  const [startDateState, setStartDateState] = useState(null);
  const [endDateState, setEndDateState] = useState(null);
  const [telState, setTelState] = useState(null);
  const [nameState, setNameState] = useState(null);
  const [productNameState, setProductNameState] = useState(null);
  const [serialNumberState, setSerialNumberState] = useState(null);
  const [statusState, setStatuseState] = useState(null);
  const [visibleDetailModal, setVisibleDetailModal] = useState(false);
  const [defaultValue, setDefaultValue] = useState({});
  const [optionBranch, setOptionBranch] = useState([]);
  const [optionProduct, setOptionProduct] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [valueSearch, setValueSearch] = useState({});

  const { user } = useCurrentState(auth);

  // const admin_branch = localStorage.getItem('admin_branch');
  const menu_user = JSON.parse(localStorage.getItem('menu_user'));
  const permissions = menu_user.length > 0 && menu_user.filter((el) => el.head === 'Ranraroeng')[0].menu;
  const perAction = permissions.length > 0 && permissions.filter((el) => el.path === '/aph-buy-second-hand-report')[0].actions;

  const [resultReport, loadingReport, errorReport, setInputReport] = useGetReportSecondHandExcel();

  useEffect(() => {
    if (resultReport?.message === 'success' && resultReport?.url) {
      setTimeout(() => {
        setLoading(false)
        window.open(resultReport?.url, '_blank', 'noopener,noreferrer');
        onClose();
      }, 1000);
    }
  }, [resultReport]);

  useEffect(() => {
    if (errorReport) {
      const message = errorReport?.response?.data?.errors;

      Modal.error({ title: 'ดึงข้อมูลไม่สำเร็จ', content: message });
      onClose();
    }
  }, [errorReport]);

  const onExportExcelReport = async () => {
    let obj = {
      branch: branchState,
      startDate: startDateState,
      endDate: endDateState,
      name: nameState,
      tel: telState,
      product: productNameState,
      serial_number: serialNumberState,
      status: statusState,
    };
    setLoading(true)
    await setInputReport(obj);
    await onClose();
  };

  useEffect(async () => {
    await getOptionBranch();
    await getOptionSecndHandProduct();
    await getDataBuySecondHandReport({
      page: pageRef.current,
      size: sizeRef.current,
      branch: '',
      start_date: '',
      end_date: '',
      tel: '',
      name: '',
      product_name: '',
      serial_number: '',
      status: '',
    });
  }, []);

  const getOptionBranch = async () => {
    try {
      const res = await POST(GET_OPTION_BRANCH_ADMIN, { group: 'APH' });
      if (res?.success) {
        const option = res?.result.map((el) => {
          if (el.value === 'ON_HOLD') {
            el.label = 'ยังไม่ได้ตัดสินใจ';
          }

          return {
            value: el.value,
            label: el.label
          };
        });

        setOptionBranch(option);
      }
    } catch (err) {
      Modal.error({
        title: err?.message || 'Error',
        content: err?.error || '',
      });
    } finally {
      setLoading(false); // ตั้งค่า loading กลับเป็น `false` เสมอไม่ว่าจะสำเร็จหรือเกิดข้อผิดพลาด
    }
  };

  const getOptionSecndHandProduct = async () => {
    try {
      let res = await GET(GET_SECOND_HAND_PRODUCT_OPTION);
      const { success, result } = res;
      if (success) {
        setOptionProduct(result);
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const optionStatus = [
    { label: 'รอเซ็นสัญญา', value: 'WAIT_SIGNATURE' },
    { label: 'รอการเงินอนุมัติ', value: 'WAIT_FINANCE_APPROVE' },
    { label: 'รอเจ้าหน้าที่อนุมัติ', value: 'WAIT_ADMIN_APPROVE' },
    { label: 'จ่ายเงินสำเร็จ', value: 'SUCCESS' },
    { label: 'ยกเลิก', value: 'TERMINATE' },
  ];

  const getDataBuySecondHandReport = async (value) => {
    value.page = value?.page || 1;
    value.size = value?.size || 10;

    pageRef.current = value.page;
    sizeRef.current = value.size;

    value.branch = value.branch || '';
    value.start_date = value.start_date ? moment(value.start_date).format('YYYY-MM-DD') : '';
    value.end_date = value.end_date ? moment(value.end_date).format('YYYY-MM-DD') : '';
    value.tel = value.tel || '';
    value.name = value.name || '';
    value.product_name = value.product_name || '';
    value.serial_number = value.serial_number || '';
    value.status = value.status || '';

    const { branch, start_date, end_date, tel, name, product_name, serial_number, status } = value;
    setLoading(true);
    setBranchState(branch);
    setStartDateState(start_date);
    setEndDateState(end_date);
    setTelState(tel);
    setNameState(name);
    setProductNameState(product_name);
    setSerialNumberState(serial_number);
    setStatuseState(status);

    setValueSearch(value);

    let obj = {
      branch: branch,
      startDate: start_date,
      endDate: end_date,
      name: name,
      tel: tel,
      product: product_name,
      serial_number: serial_number,
      status: status,
    };

    try {
      const res = await POST(GET_APH_SECOND_HAND_PRODUCT_LIST(pageRef.current, sizeRef.current), obj);
      const { success, data } = res;

      if (success) {
        setTotalSize(data.totalItem);
        setTotalPage(data.totalPage);
        // setTotalSize
        const result = data.secondHandList.map((el) => {
          return {
            ...el,
            key: uuidv4(),
            name: el?.user?.name || '',
            tel: convertStrToFormat(el?.user?.tel, 'phone_number') || '',
            date: el?.date ? moment(el?.date).format('DD/MM/YYYY HH:mm') : '',
            branch: el?.branch?.name || '',
            approve_paid_amount: convertStrToFormat(el.approve_paid_amount, 'money') || '',
            branchCode: el?.branch?.code
          };
        });
        setDataSource(result);
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const openModal = (record, name) => {
    if (record) {
      if (name === 'EDIT_REMARK') {
        setDefaultValue(record);
        setVisibleEditModal(true);
      } else if (name === 'DETAIL') {
        getDataDetail(record.docNo);
      } else if (name === 'CANCEL') {
        // setDefaultValue(record);
        openConfirm(record.docNo);
      }
    }
  };

  const onPageSizeChangeCustom = (pageData, sizeData) => {
    const sizeChange = sizeRef.current !== sizeData;
    if (sizeChange) {
      // setPageState(1)
      pageRef.current = 1;
    } else {
      // setPageState(pageData)
      pageRef.current = pageData;
    }
    sizeRef.current = sizeData;

    let branch = branchState;
    let start_date = startDateState;
    let end_date = endDateState;
    let tel = telState;
    let name = nameState;
    let product_name = productNameState;
    let serial_number = serialNumberState;
    let status = statusState;

    const params = {
      page: pageRef.current,
      size: sizeRef.current,
      branch: branch || '',
      start_date: start_date,
      end_date: end_date,
      tel: tel || '',
      name: name || '',
      product_name: product_name || '',
      serial_number: serial_number || '',
      status: status || '',
    };

    getDataBuySecondHandReport(params);
  };

  const onClearCustom = (setValue, formSearch) => {
    formSearch.map((val) => setValue(val.name, null));

    setBranchState('');
    setStartDateState('');
    setEndDateState('');
    setTelState('');
    setNameState('');
    setProductNameState('');
    setSerialNumberState('');
    setStatuseState('');
    pageRef.current = 1;
    sizeRef.current = 10;
  };

  const onClose = () => setVisibleModal(false);

  const getDataDetail = async (value) => {
    setLoading(true);
    if (value === undefined) {
      Modal.error({
        title: 'ไม่พบเลขที่เอกสารจากรายงานฉบับนี้',
        content: 'ไม่สามารถดึงรายละเอียดข้อมูลมาแสดงได้',
      });
      setLoading(false);
    } else {
      try {
        const res = await POST(POST_APH_SECOND_HAND_DETAIL(value));
        const { success, result } = res;
        if (success) {
          const newResult = {
            key: result?.ObjectId,
            document_no: value,
            pdf_url: result?.pdfContract,
            profile_image: result?.imageFrontIdCard || '',
            book_bank_image: result?.bookBankImage || '',
            signature: result?.imageSignature || '',
            imageMobile: result?.imageMobile || [],
            imageDocument: result?.imageDocument || [],
            id_card: result?.id_card,
            name: result?.name,
            tel: result?.tel,
            status: result?.status,
            product_name: result?.product,
            serial_no: result?.serialNo,
            imei: result?.imei,
            condition: result?.condition,
            insurance_end_date: result?.insurance_end_date ? moment(result?.insurance_end_date).format('DD/MM/YYYY') : '',
            approve_paid_amount: result?.approve_paid_amount ? convertStrToFormat(result?.approve_paid_amount, 'money') : 0,
            approve_date: result?.approve_date ? moment(result?.approve_date).format('DD/MM/YYYY') : '',
            terminate_date: result?.terminate_date ? moment(result?.terminate_date).format('DD/MM/YYYY') : '',
            branch: result?.branch,
            branchCode: result?.branchCode,
            remark: result?.remark,
            source_type: result?.source_type,
            request_no: result?.request_no,
            url_sign: result?.url_sign
          };

          setDefaultValue(newResult);
          setVisibleDetailModal(true);
        }
      } catch (err) {
        const { message, error } = err;
        Modal.error({
          title: message,
          content: error || '',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const openConfirm = (value) => {
    Modal.confirm({
      title: 'ยืนยัน',
      icon: <Icon.warningAlert />,
      content: 'ยืนยันการยกเลิก',
      onOk() {
        cancelSecondHand(value);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const cancelSecondHand = async (docNo) => {
    setLoading(true);
    try {
      const res = await POST(CANCEL_APH_SECOND_HAND, { document_no: docNo });
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            getDataBuySecondHandReport(valueSearch);
          },
        });
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
        <Spin tip="Loading..." spinning={loading} >
      <Card>
          <PageLayout
            searchLayout={{
              title: 'รายงานรับซื้อสินค้ามือสอง',
              icon: <BookOutlined />,
              style: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
              // spanSearch: 24,
              formSearch: BuySecondHandReportSearchForm({ options: { optionBranch, optionProduct, optionStatus } }),
              onSearch: (data) => getDataBuySecondHandReport(data),
              onClearCustom,
            }}
            tableLayout={{
              columns: BuySecondHandReportColumn({ options: {}, openModal, user, permissions: perAction }),
              dataSource: dataSource,
              rowKey: (record) => record?.key,
              pagination: {
                showSizeChanger: true,
                current: pageRef.current,
                pageSize: sizeRef.current,
                onChange: (p, s) => onPageSizeChangeCustom(p, s),
                pageSizeOptions: ['10', '20', '30', '40', '100', '200', '300'],
                total: totalSize || 0,
                showTotal: (totalSize, range) => `รายการทั้งหมด ${convertStrToFormat(totalSize, 'number-comma')} รายการ`,
                defaultPageSize: sizeRef.current,
                defaultCurrent: 1,
              },
              extraContent: (
                <ContainerButton right>
                  <ButtonTheme useFor="DOWNLOAD" title={'ดาวน์โหลด Excel'} onClick={() => setVisibleModal(true)} />
                </ContainerButton>
              ),
            }}
          />
      </Card>
      <Modal
        title={'ต้องการดาวน์โหลด Excel?'}
        open={visibleModal}
        width={550}
        onCancel={onClose}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}>
        <ContainerButton right>
          <ButtonTheme useFor="CUSTOM" title={'ตกลง'} onClick={async () => await onExportExcelReport()} />
          <ButtonTheme useFor="CANCEL" onClick={() => onClose()} />
        </ContainerButton>
      </Modal>
      <EditRemarkSecondHandModal
        visible={visibleEditModal}
        title={'แก้ไขหมายเหตุ'}
        onClose={() => setVisibleEditModal(false)}
        data={{ reApi: getDataBuySecondHandReport, record: defaultValue, valueSearch }}
      />
      <DetailModal
        visible={visibleDetailModal}
        title={'รายละเอียด'}
        onClose={() => setVisibleDetailModal(false)}
        data={{ reApi: getDataBuySecondHandReport, reApi2: getDataDetail, record: defaultValue, valueSearch }}
      />
      </Spin>
    </Wrapper>
  );
};

export default AphBuySecondHandReportScreen;
