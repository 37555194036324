export const createParams = (statusParam, userIdCardParam, nameParam, telParam, pageParam, sizeParam, startDate, createdDate, type) => {
  let params = `?size=${sizeParam}&page=${pageParam}`;
  if (userIdCardParam !== null && userIdCardParam !== '') {
    params += `&user_id_card=${userIdCardParam}`;
  }
  if (nameParam !== null && nameParam !== '') {
    params += `&name=${nameParam}`;
  }
  if (telParam !== null && telParam !== '') {
    params += `&tel=${telParam}`;
  }
  if (statusParam !== 'ALL') {
    params += `&status=${statusParam}`;
  }
  if (startDate) {
    params += `&start_date=${startDate.format("YYYY-MM-DD")}`;
  }
  if (createdDate) {
    params += `&created_date=${createdDate.format("YYYY-MM-DD")}`;
  }
  if (type) {
    params += `&type=${type}`;
  }
  return params;
};

export const createParamsExport = (statusParam, userIdCardParam, nameParam, telParam, startDate, createdDate, type) => {
  let params = '?';
  if (userIdCardParam !== null && userIdCardParam !== '') {
    params += `user_id_card=${userIdCardParam}&`;
  }
  if (nameParam !== null && nameParam !== '') {
    params += `name=${nameParam}&`;
  }
  if (telParam !== null && telParam !== '') {
    params += `tel=${telParam}&`;
  }
  if (statusParam !== 'ALL') {
    params += `status=${statusParam}&`;
  }
  if (startDate) {
    params += `start_date=${startDate.format("YYYY-MM-DD")}&`;
  }
  if (createdDate) {
    params += `created_date=${createdDate.format("YYYY-MM-DD")}&`;
  }
  if (type) {
    params += `type=${type}`;
  }
  return params;
};

export const translateStatus = (status) => {
  switch (status) {
    case 'CONFIRMED':
      return 'ยืนยันแล้ว';
    case 'CANCELED':
      return 'ยกเลิกแล้ว';
    case 'PENDING':
      return 'รอยืนยัน';
    default:
      return 'ทั้งหมด';
  }
};
export const translateType = (type) => {
  switch (type) {
    case 'INSTALLMENT':
      return 'ชำระค่างวด';
    case 'DOWN_PAYMENT':
      return 'ออมดาวน์';
    case 'COUPON':
      return 'คูปอง';
    case 'FGF':
      return 'คอมมิชชั่น';
    default:
      return 'ทั้งหมด';
  }
};