import {UPLOAD_FILE} from '../../../../services/index';
import {API_URL, GCS_PATH_REGISTER, GCS_BUCKET_REGISTER} from '../../../../env';
import _ from 'lodash';
import {
  optionBusinessDoc,
  optionDegree,
  optionSaleChannel,
  optionProductGroup,
  optionProductHand,
  optionSourceIncomeStudent,
} from '../hook/useGetOptions';
import moment from 'moment';
import {getMonthMinusMonths, monthShortOnly} from '../../../../functions/fn';

export const MonitorCustomerSearchForm = ({defaultSearch, onClearCustomField, handleRequiredDate, onChangeSelectStatus, options, data}) => {
  return [
    {
      key: '1',
      span: 6,
      name: 'fullName',
      label: 'ชื่อ-นามสกุล',
      placeholder: 'กรุณากรอกชื่อ-นามสกุล',
      type: 'INPUT',
      allowClear: true,
      value: '',
    },
    {
      key: '2',
      span: 6,
      name: 'tel',
      label: 'เบอร์โทรศัพท์',
      placeholder: 'กรุณากรอกเบอร์โทรศัพท์',
      type: 'INPUT',
      allowClear: true,
      value: '',
    },
    {
      key: '3',
      span: 6,
      name: 'status',
      label: 'สถานะ',
      placeholder: 'เลือกสถานะ',
      type: 'SELECT',
      options: options.optionsConsiderStatus,
      allowClear: true,
      // defaultValue: defaultSearch.status,
      onSelect: (value) => {
        onChangeSelectStatus(value);
      },
      value: defaultSearch.status !== null ? defaultSearch.status : null,
      onClear: () => onClearCustomField(),
    },
    {
      key: '4',
      span: 6,
      name: 'type',
      label: 'ประเภท',
      placeholder: 'เลือกประเภท',
      type: 'SELECT',
      options: options.type,
      allowClear: true,
      // value: '',
    },
    {
      key: '5',
      span: 6,
      name: 'dateType',
      label: 'ตัวเลือกค้นหาจากวันที่',
      placeholder: 'เลือกตัวเลือกค้นหา',
      type: 'SELECT',
      options: options.dateType,
      allowClear: true,
      onChangeCustom: (e) => handleRequiredDate(e, 'dateType'),
      rules: {
        required: data?.requiredDate,
      },
    },
    {
      key: '6',
      span: 6,
      name: 'startDate',
      label: 'เริ่มค้นหาจากวันที่',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
      onChange: (e) => handleRequiredDate(e, 'startDate'),
      rules: {
        required: data?.requiredDate,
      },
    },
    {
      key: '7',
      span: 6,
      name: 'endDate',
      label: 'ถึงวันที่',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
      onChange: (e) => handleRequiredDate(e, 'endDate'),
      rules: {
        required: data?.requiredDate,
      },
    },
    {
      key: '8',
      span: 6,
      name: 'walk_in',
      label: 'Walk in',
      placeholder: 'เลือก Walk in',
      type: 'SELECT',
      options: options.optionWalkIn,
      allowClear: true,
    },
    {
      key: '9',
      span: 6,
      name: 'branch',
      label: 'สาขา',
      placeholder: 'กรุณาเลือกสาขา',
      type: 'SELECT',
      options: options.branchSpecificList,
      allowClear: true,
      value: '',
    },
    {
      key: '10',
      span: 6,
      name: 'productHand',
      label: 'ลักษณะสินค้า',
      placeholder: 'กรุณาเลือกลักษณะสินค้า',
      type: 'SELECT',
      options: options.optionProductHandFilter,
      allowClear: true,
      value: '',
    },
    {
      key: '11',
      span: 6,
      name: 'remark',
      label: 'หมายเหตุ',
      placeholder: 'กรุณากรอกหมายเหตุ',
      type: 'INPUT',
      allowClear: true,
      value: '',
    },
  ];
};

export const FORM_PRODUCT_INSTALLMENT = ({options, fn, data, disabledField}) => {
  const {optionsColor, optionsStorage, optionsProducts, optionsPeriod, type, optionsBranchUser, newDownPayment, optionAdditionalCheckbox} = options;
  const {productGroup, productCombo, comboProductForm} = data;
  let comboOption = [];

  Object.entries(comboProductForm).map((item, idx) => {
    let option = item[1].map((el) => {
      return {
        value: el.code,
        label: el.name,
      };
    });
    const comboName = `combo_${item[0]}`;
    const shouldBeHidden =
      productGroup !== 'Tablet' || (optionAdditionalCheckbox && optionAdditionalCheckbox.length === 0) || !productCombo.includes(item[0]);

    comboOption.push({
      key: idx + 1,
      name: comboName,
      label: item[0],
      type: 'SELECT',
      disabled: disabledField,
      span: 24,
      options: option || [],
      hidden: productGroup !== 'Tablet' || shouldBeHidden,
      // defaultValue:product && product.color,
      onSelect: (value) => {
        fn.onChangeCombo(value, comboName);
      },
      // rules: {
      //   required: true,
      // },
    });
  });
  return [
    {
      key: '1',
      name: 'type',
      label: 'ประเภท',
      type: 'SELECT',
      span: 24,
      options: type || [],
      disabled: true,
    },
    {
      key: '2',
      name: 'productHand',
      label: 'ลักษณะสินค้า',
      type: 'SELECT',
      span: 12,
      options: optionProductHand || [],
      onSelect: fn.onChange,
      disabled: disabledField,
      // rules: {
      //   required: true,
      // },
    },
    {
      key: '3',
      name: 'productGroup',
      label: 'กลุ่มสินค้า',
      type: 'SELECT',
      span: 12,
      options: optionProductGroup || [],
      onSelect: fn.onChange,
      disabled: disabledField,
      // rules: {
      //   required: true,
      // },
    },
    {
      key: '4',
      name: 'model',
      label: 'สินค้า',
      type: 'SELECT',
      placeholder: 'เลือกสินค้า',
      disabled: disabledField,
      span: 24,
      options: optionsProducts || [],
      onSelect: fn.onChange,
      rules: {
        required: true,
      },
    },
    {
      key: '5',
      name: 'storage',
      label: 'ความจุ',
      type: 'SELECT',
      placeholder: 'เลือกความจุ',
      disabled: disabledField,
      span: 12,
      options: optionsStorage || [],
      // defaultValue:product && product.storage,
      onSelect: fn.onChange,
      rules: {
        required: true,
      },
    },
    {
      key: '6',
      name: 'color',
      label: 'สี',
      type: 'SELECT',
      placeholder: 'เลือกสี',
      disabled: disabledField,
      span: 12,
      options: optionsColor || [],
      // defaultValue:product && product.color,
      onSelect: fn.onChange,
      rules: {
        required: true,
      },
    },
    {
      key: '7',
      name: 'additionalProduct',
      label: 'อุปกรณ์เสริม',
      span: 24,
      hidden: productGroup !== 'Tablet' || (optionAdditionalCheckbox && optionAdditionalCheckbox.length === 0),
      type: 'CHECKBOX_OPTION',
      disabled: disabledField,
      options: optionAdditionalCheckbox || [],
      // options: [{ value: 'Pencil', label: 'Pencil' }],
      onChangeCustom: fn.onChange,
    },
    ...comboOption,
    {
      key: '8',
      name: 'period',
      label: 'งวด',
      type: 'SELECT',
      placeholder: 'เลือกงวด',
      disabled: disabledField,
      span: 24,
      options: optionsPeriod || [],
      // defaultValue:period,
      onSelect: fn.onChange,
      rules: {
        required: true,
      },
    },
    {
      key: '7',
      name: 'downPayment',
      label: 'จำนวนเงินดาวน์',
      type: 'INPUT',
      span: 12,
      number: true,
      disabled: true,
    },
    {
      key: '8',
      name: 'installments',
      label: 'จำนวนเงินผ่อน',
      type: 'INPUT',
      span: 12,
      number: true,
      disabled: true,
    },
    {
      key: '9',
      name: 'newPeriod',
      label: 'งวดที่จะอนุมัติ',
      type: 'SELECT',
      span: 24,
      placeholder: 'เลือกงวด',
      disabled: disabledField ? disabledField : newDownPayment ? false : true,
      options: optionsPeriod || [],
      onSelect: fn.onChange,
      rules: {
        required: {
          value: newDownPayment ? true : false,
        },
      },
    },
    {
      key: '10',
      name: 'newDownPayment',
      label: 'จำนวนเงินดาวน์ที่จะอนุมัติ',
      type: 'INPUT_NUMBER',
      span: 12,
      number: true,
      // defaultValue:summary && convertStrToFormat(summary.downPayment,"money") || '',
      disabled: disabledField,
      onChangeCustom: fn.onChange,
    },
    {
      key: '11',
      name: 'newInstallment',
      label: 'จำนวนเงินผ่อนที่จะอนุมัติ',
      type: 'INPUT',
      span: 12,
      number: true,
      // defaultValue:summary && convertStrToFormat(summary.installments,"money") || '',
      disabled: true,
    },
    {
      key: '12',
      name: 'interestedBranch',
      label: 'สาขาที่สนใจ',
      type: 'SELECT',
      span: 24,
      options: optionsBranchUser || [],
      onSelect: fn.onChange,
      // defaultValue:summary && convertStrToFormat(summary.installments,"money") || '',
      disabled: disabledField,
      rules: {
        required: true,
      },
    },
  ];
};

export const FORM_PRODUCT_REFINANCE = ({options, fn, disabledField}) => {
  const {
    optionsColor,
    optionsStorage,
    optionsProducts,
    type,
    optionsBranchUser,
    optionRefinancePeriod,
    occupation,
    optionRefinanceNewPeriod,
    newMaximum,
  } = options;

  return [
    {
      key: '1',
      name: 'type',
      label: 'ประเภท',

      type: 'SELECT',
      span: 24,
      options: type || [],
      disabled: true,
    },
    {
      key: '2',
      name: 'productGroup',
      label: 'กลุ่มสินค้า',
      type: 'SELECT',
      span: 24,
      options: optionProductGroup || [],
      onSelect: fn.onChange,
      disabled: disabledField,
      // rules: {
      //   required: true,
      // },
    },
    {
      key: '3',
      name: 'model',
      label: 'สินค้า',
      type: 'SELECT',
      placeholder: 'เลือกสินค้า',
      disabled: disabledField,
      span: 24,
      options: optionsProducts || [],
      // defaultValue:product && product.model,
      onSelect: fn.onChange,
      rules: {
        required: true,
      },
    },
    {
      key: '4',
      name: 'storage',
      label: 'ความจุ',
      type: 'SELECT',
      placeholder: 'เลือกความจุ',
      disabled: disabledField,
      span: 12,
      options: optionsStorage || [],
      // defaultValue:product && product.storage,
      onSelect: fn.onChange,
      rules: {
        required: true,
      },
    },
    {
      key: '5',
      name: 'color',
      label: 'สี',
      type: 'SELECT',
      placeholder: 'เลือกสี',
      disabled: disabledField,
      span: 12,
      options: optionsColor || [],
      // defaultValue:product && product.color,
      onSelect: fn.onChange,
      rules: {
        required: true,
      },
    },
    {
      key: '6',
      name: 'period',
      label: 'งวด',
      // type: 'INPUT',
      type: 'SELECT',
      span: 24,
      // disabled: true,
      placeholder: 'เลือกงวด',
      disabled: disabledField,
      options: optionRefinancePeriod || [],
      onSelect: fn.onChange,
      rules: {
        required: {
          value: true,
        },
      },
    },
    {
      key: '7',
      name: 'maximum',
      label: 'วงเงินสูงสุด',

      type: 'INPUT',
      span: 12,
      number: true,
      disabled: true,
    },
    {
      key: '8',
      name: 'installments',
      label: 'จำนวนเงินผ่อน',
      type: 'INPUT',
      span: 12,
      number: true,
      disabled: true,
    },
    {
      key: '9',
      name: 'newPeriod',
      label: 'งวดที่จะอนุมัติ',
      type: 'SELECT',
      span: 24,
      placeholder: 'เลือกงวด',
      disabled: disabledField ? disabledField : newMaximum ? false : true,
      options: optionRefinanceNewPeriod || [],
      onSelect: fn.onChange,
      rules: {
        required: {
          value: newMaximum ? true : false,
        },
      },
    },
    {
      key: '10',
      name: 'newMaximum',
      label: 'วงเงินที่จะอนุมัติ',
      type: 'INPUT_NUMBER',
      span: 12,
      number: true,
      // defaultValue:summary && convertStrToFormat(summary.downPayment,"money") || '',
      disabled: disabledField,
      onChangeCustom: fn.onChange,
    },
    {
      key: '11',
      name: 'newInstallment',
      label: 'จำนวนเงินผ่อนที่จะอนุมัติ',
      type: 'INPUT',
      span: 12,
      number: true,
      // defaultValue:summary && convertStrToFormat(summary.installments,"money") || '',
      disabled: true,
    },
    {
      key: '12',
      name: 'interestedBranch',
      label: 'สาขาที่สนใจ',
      type: 'SELECT',
      span: 24,
      options: optionsBranchUser || [],
      onSelect: fn.onChange,
      // defaultValue:summary && convertStrToFormat(summary.installments,"money") || '',
      disabled: disabledField,
      rules: {
        required: true,
      },
    },
  ];
};

export const FORM_PERSONAL_INFO = ({options, fn, data, disabledField}) => {
  const {typeInstallment, checkPrefix, imeiImageList, iphoneImageList} = data;
  const {optionsPrefix, optionProvince, optionAmphoe, optionDistrict, optionsCountPerson, optionsPhoneSystem} = options;

  return [
    {
      key: '3',
      name: 'prefix',
      label: 'คำนำหน้า',
      type: 'SELECT',
      placeholder: 'เลือกคำนำหน้า',
      disabled: disabledField,
      span: checkPrefix ? 8 : 3,
      options: optionsPrefix || [],
      onSelect: fn.onChange,
      rules: {
        required: true,
      },
    },
    {
      key: '4',
      name: 'otherPrefix',
      label: 'โปรดระบุ',
      disabled: disabledField,
      type: 'INPUT',
      span: checkPrefix ? 8 : 5,
      hidden: checkPrefix,
      rules: {
        required: !checkPrefix,
      },
    },
    {
      key: '5',
      name: 'firstName',
      label: 'ชื่อ',
      placeholder: 'กรอกชื่อ',
      disabled: disabledField,
      type: 'INPUT',
      span: 8,
      rules: {
        required: true,
      },
    },
    {
      key: '6',
      name: 'lastName',
      label: 'นามสกุล',
      placeholder: 'กรอกนามสกุล',
      disabled: disabledField,
      type: 'INPUT',
      span: 8,
      rules: {
        required: true,
      },
    },
    {
      key: '1',
      name: 'idCard',
      label: 'เลขบัตรประชาชน',
      placeholder: 'กรอกเลขบัตรประชาชน',
      disabled: disabledField,
      type: 'INPUT',
      span: 8,
      onChangeCustom: fn.onChange,
      showRequired: true,
    },
    {
      key: '7',
      name: 'dateOfBirth',
      label: 'วัน/เดือน/ปีเกิด (พ.ศ.)',
      disabled: disabledField,
      type: 'INPUT',
      placeholder: 'ตัวอย่าง. 05/07/2537 (DD/MM/YYYY)',
      span: 8,
      // showRequired: true,
      onChangeCustom: fn.onChange,
    },
    {
      key: '7',
      name: 'age',
      label: 'อายุ',
      disabled: true,
      type: 'INPUT',
      span: 8,
      rules: {
        required: false,
      },
      onChange: fn.onChange,
    },
    {
      key: '2',
      name: 'phoneNumber',
      label: 'เบอร์โทรศัพท์',
      placeholder: 'กรอกเบอร์โทรศัพท์',
      disabled: disabledField,
      type: 'INPUT',
      span: 6,
      rules: {
        required: true,
        maxLength: {
          value: 10,
          message: 'ระบุจำนวน 10 ตัวอักษร',
        },
        minLength: {
          value: 10,
          message: 'ระบุจำนวน 10 ตัวอักษร',
        },
        pattern: {
          value: /^[0-9]+$/,
          message: 'กรอกเฉพาะตัวเลขเท่านั้น',
        },
      },
    },
    {
      key: '8',
      name: 'address',
      label: 'ที่อยู่อาศัยปัจจุบัน',
      placeholder: 'บ้านเลขที่ / หมู่บ้าน / อาคาร',
      disabled: disabledField,
      type: 'INPUT',
      span: 18,
      rules: {
        required: true,
      },
    },
    {
      key: '9',
      name: 'zipcode',
      label: 'รหัสไปรษณีย์',
      placeholder: 'กรอกรหัสไปรษณีย์',
      disabled: disabledField,
      onChangeCustom: fn.onChange,
      type: 'INPUT',
      span: 6,
      rules: {
        required: true,
        maxLength: {
          value: 5,
          message: 'ระบุจำนวน 5 ตัวอักษร',
        },
        minLength: {
          value: 5,
          message: 'ระบุจำนวน 5 ตัวอักษร',
        },
        pattern: {
          value: /^[0-9]+$/,
          message: 'ต้องเป็นตัวเลขเท่านั้น',
        },
      },
    },
    {
      key: '10',
      name: 'province',
      label: 'จังหวัด',
      type: 'SELECT',
      placeholder: 'เลือกจังหวัด',
      disabled: disabledField,
      span: 6,
      allowClear: false,
      options: optionProvince || [],
      onSelect: fn.onChange,
      rules: {
        required: true,
      },
    },
    {
      key: '11',
      name: 'district',
      label: 'เขต/อำเภอ',
      type: 'SELECT',
      placeholder: 'เลือกเขต/อำเภอ',
      disabled: disabledField,
      span: 6,
      allowClear: false,
      options: optionAmphoe || [],
      onSelect: fn.onChange,
      rules: {
        required: true,
      },
    },
    {
      key: '12',
      name: 'subdistrict',
      label: 'แขวง/ตำบล',
      type: 'SELECT',
      allowClear: false,
      placeholder: 'เลือกแขวง/ตำบล',
      disabled: disabledField,
      span: 6,
      options: optionDistrict || [],
      rules: {
        required: true,
      },
    },
    {
      key: '13',
      name: 'facebook',
      label: 'Facebook',
      placeholder: 'กรอก Facebook',
      disabled: disabledField,
      type: 'INPUT',
      span: 12,
      rules: {
        required: true,
      },
    },
    {
      key: '14',
      name: 'lineId',
      label: 'ID Line',
      placeholder: 'กรอก ID Line',
      disabled: disabledField,
      type: 'INPUT',
      span: 12,
      // rules: {
      //   required: true,
      // },
    },
    {
      key: '15',
      name: 'countPerson',
      label: 'จำนวนคนที่ต้องดูแลค่าใช้จ่าย',
      disabled: disabledField,
      type: 'SELECT',
      placeholder: 'เลือกจำนวนคนที่ต้องดูแลค่าใช้จ่าย',
      span: 12,
      options: optionsCountPerson || [],
      // rules: {
      //   required: true,
      // },
    },
    {
      key: '16',
      name: 'phoneSystem',
      label: 'ท่านใช้ระบบโทรศัพท์มือถือแบบใด',
      disabled: disabledField,
      type: 'SELECT',
      placeholder: 'เลือกระบบโทรศัพท์มือถือ',
      span: 12,
      options: optionsPhoneSystem || [],
      rules: {
        required: true,
      },
      onSelect: fn.onChange,
    },
    {
      key: '17',
      name: 'serial_number',
      label: 'เลขประจำเครื่อง (Serial)',
      placeholder: 'กรอกเลขประจำเครื่อง',
      disabled: disabledField,
      onChange: fn.onChange,
      type: 'INPUT',
      hidden: typeInstallment === 'INSTALLMENT' || !typeInstallment,
      span: 24,
      // rules: {
      //   required: {
      //     value: true,
      //   },
      //   maxLength: {
      //     value: 12,
      //     message: 'ระบุจำนวน 12 ตัวอักษร',
      //   },
      //   minLength: {
      //     value: 12,
      //     message: 'ระบุจำนวน 12 ตัวอักษร',
      //   },
      //   pattern: {
      //     value: /^[0-9A-Z]+$/,
      //     message: 'ต้องเป็นตัวเลขและภาษาอังกฤษตัวพิมพ์ใหญ่เท่านั้น',
      //   },
      // },
    },
    {
      key: '18',
      name: 'imeiImages',
      label: 'รูปเลขประจำเครื่อง (Serial)',
      disabled: disabledField,
      type: 'FILE_UPLOAD_V2',
      hidden: typeInstallment === 'INSTALLMENT' || !typeInstallment,
      span: 24,
      spanMd: 24,
      accept: 'image/*',
      bucket: `${GCS_BUCKET_REGISTER}`,
      subDir: `${GCS_PATH_REGISTER}`,
      defaultFileList: imeiImageList,
      handleChange: (e) => fn.onChangeUploadImage(e, 'imeiImages'),
      setLoading: fn.setLoadingUpload,
    },
    {
      key: '19',
      name: 'iphoneImages',
      label: 'รูป iPhone Storage',
      disabled: disabledField,
      type: 'FILE_UPLOAD_V2',
      hidden: typeInstallment === 'INSTALLMENT' || !typeInstallment,
      span: 24,
      spanMd: 24,
      accept: 'image/*',
      bucket: `${GCS_BUCKET_REGISTER}`,
      subDir: `${GCS_PATH_REGISTER}`,
      defaultFileList: iphoneImageList,
      handleChange: (e) => fn.onChangeUploadImage(e, 'iphoneImages'),
      setLoading: fn.setLoadingUpload,
    },
  ];
};

export const FORM_OCCUPATION = ({options, fn, data, disabledField}) => {
  const {
    optionsDoc,
    optionsOccupation,
    optionsJobDescription,
    optionsExperience,
    optionAvgIncome,
    optionStudentGrade,
    optionsBusinessFullTime,
    optionsBusinessFullTime2,
    optionsBusinessPartTime,
    optionsBusinessGovernment,
    optionsBusinessOwn,
  } = options;
  const {
    occupation,
    jobDescriptionState,
    incomeDoc,
    fileListStudentCardImages,
    fileListStudentTranscriptImages,
    fileListStatement,
    proveOwnBusinessImageList,
    fileListGovernmentCardImage,
    business,
    businessFullTime,
    proveWork,
    riderAppicationImage,
    typeInstallment,
    proveOwnBusinessImageFlag,
    saleChannel,
    registeredBusinessDocImage,
    unRegisteredbusinessDocImage,
    productShopSaleImage,
    visibleBusinessDocField,
  } = data;

  const date = new Date()
  const pastDate = getMonthMinusMonths(date, 3);
  const toDate = getMonthMinusMonths(date, 1);
  const prev3Month = monthShortOnly(pastDate.getMonth())
  const month = monthShortOnly(toDate.getMonth())
  const label3Month = `Statement ${prev3Month} - ${month}`;

  const checkCondition = () => {
    if (occupation === 'นักเรียน/นักศึกษา') {
      return false;
    }
    if (jobDescriptionState === 'พนักงานราชการ' || jobDescriptionState === 'พนักงานเงินเดือน') {
      return false;
    }

    if (jobDescriptionState === 'พนักงานชั่วคราว/พนักงานอิสระ' || jobDescriptionState === 'เจ้าของกิจการ') {
      return true;
    }

    return incomeDoc === 'NO' || !incomeDoc || !occupation;
  };

  const conditionUploadFile = checkCondition();
  return [
    {
      key: '1',
      name: 'occupation',
      label: 'อาชีพ',
      type: 'SELECT',
      placeholder: 'เลือกอาชีพ',
      disabled: disabledField,
      span: 24,
      options: optionsOccupation || [],
      onSelect: fn.onChange,
      rules: {
        required: true,
      },
    },
    {
      key: '2',
      name: 'college',
      label: 'มหาวิทยาลัย',
      placeholder: 'กรอกมหาวิทยาลัย',
      disabled: disabledField,
      type: 'INPUT',
      span: 8,
      hidden: occupation === 'บุคคลทั่วไป' || !occupation,
      rules: {
        required: true,
      },
    },
    {
      key: '3',
      name: 'faculty',
      label: 'คณะ',
      placeholder: 'กรอกคณะ',
      disabled: disabledField,
      type: 'INPUT',
      hidden: occupation === 'บุคคลทั่วไป' || !occupation,
      span: 8,
      rules: {
        required: true,
      },
    },
    {
      key: '4',
      name: 'branch',
      label: 'สาขาวิชา',
      placeholder: 'กรอกสาขาวิชา',
      disabled: disabledField,
      type: 'INPUT',
      hidden: occupation === 'บุคคลทั่วไป' || !occupation,
      span: 8,
      rules: {
        required: true,
      },
    },
    {
      key: '5',
      name: 'degree',
      label: 'ระดับการศึกษา',
      placeholder: 'เช่น ปริญญาตรี',
      disabled: disabledField,
      type: 'SELECT',
      options: optionDegree,
      hidden: occupation === 'บุคคลทั่วไป' || !occupation,
      span: 8,
      rules: {
        required: true,
      },
    },
    {
      key: '6',
      name: 'grade',
      label: 'ชั้นปีที่',
      placeholder: 'กรอกชั้นปีที่',
      disabled: disabledField,
      type: 'SELECT',
      options: optionStudentGrade || [],
      hidden: occupation === 'บุคคลทั่วไป' || !occupation,
      span: 8,
      rules: {
        required: true,
      },
    },
    {
      key: '7',
      name: 'year',
      label: 'ปี พ.ศ. ที่เข้าเรียน',
      placeholder: 'กรอกปี พ.ศ. ที่เข้าเรียน',
      disabled: disabledField,
      type: 'INPUT',
      hidden: occupation === 'บุคคลทั่วไป' || !occupation,
      span: 8,
      onChangeCustom: fn.onChange,
    },
    {
      key: '8',
      name: 'avgIncome',
      label: 'รายได้เฉลี่ยต่อเดือน',
      placeholder: 'กรอกรายได้เฉลี่ยต่อเดือน',
      disabled: disabledField,
      type: 'SELECT',
      options: optionAvgIncome || [],
      hidden: occupation === 'บุคคลทั่วไป' || !occupation,
      span: 12,
      rules: {
        required: true,
      },
    },
    {
      key: '8',
      name: 'chanelSalary',
      label: 'แหล่งที่มารายได้',
      placeholder: 'เลือกแหล่งที่มารายได้',
      disabled: disabledField,
      type: 'SELECT',
      options: optionSourceIncomeStudent || [],
      hidden: occupation === 'บุคคลทั่วไป' || !occupation,
      span: 12,
      // rules: {
      //   required: true,
      // },
    },
    {
      key: '9',
      name: 'studentCardImages',
      label: 'บัตรนักศึกษา',
      disabled: disabledField,
      type: 'FILE_UPLOAD_V2',
      hidden: occupation === 'บุคคลทั่วไป' || !occupation,
      span: 12,
      spanMd: 12,
      maxCount: 2,
      accept: 'image/*',
      bucket: `${GCS_BUCKET_REGISTER}`,
      subDir: `${GCS_PATH_REGISTER}`,
      defaultFileList: fileListStudentCardImages,
      handleChange: (e) => fn.onChangeUploadImage(e, 'studentCardImages'),
      setLoading: fn.setLoadingUpload,
    },
    {
      key: '9',
      name: 'studentTranscriptImages',
      label: 'ระเบียนการศึกษา (ที่ยืนยันสถานะว่าเป็นนักศึกษาอยู่)',
      disabled: disabledField,
      type: 'FILE_UPLOAD_V2',
      hidden: occupation === 'บุคคลทั่วไป' || !occupation,
      span: 12,
      spanMd: 12,
      maxCount: 2,
      accept: 'image/*',
      bucket: `${GCS_BUCKET_REGISTER}`,
      subDir: `${GCS_PATH_REGISTER}`,
      defaultFileList: fileListStudentTranscriptImages,
      handleChange: (e) => fn.onChangeUploadImage(e, 'studentTranscriptImages'),
      setLoading: fn.setLoadingUpload,
    },
    {
      key: '10',
      name: 'jobDescription',
      label: 'ลักษณะอาชีพ',
      placeholder: 'เลือกลักษณะอาชีพ',
      disabled: disabledField,
      type: 'SELECT',
      span: !jobDescriptionState
        ? 24
        : jobDescriptionState && jobDescriptionState !== 'พนักงานเงินเดือน'
        ? 12
        : jobDescriptionState && jobDescriptionState === 'พนักงานเงินเดือน'
        ? 24
        : 24,
      hidden: occupation === 'นักเรียน/นักศึกษา' || !occupation,
      options: optionsJobDescription || [],
      onSelect: fn.onChange,
      rules: {
        required: true,
      },
    },
    {
      key: '11',
      name: 'businessFullTime',
      label: 'ลักษณะงานประจำ',
      placeholder: 'เลือกลักษณะงานประจำ',
      disabled: disabledField,
      type: 'SELECT_GROUP',
      span: businessFullTime === 'OTHER' ? 12 : 24,
      hidden: occupation === 'นักเรียน/นักศึกษา' || jobDescriptionState !== 'พนักงานเงินเดือน' || !occupation,
      options: optionsBusinessFullTime || [],
      rules: {
        required: true,
      },
      onSelect: fn.onChange,
    },
    {
      key: '11',
      name: 'businessFullTime2',
      label: 'ลักษณะงานประจำ (เพิ่ม)',
      placeholder: 'เลือกลักษณะงานประจำ',
      disabled: disabledField,
      type: 'SELECT_GROUP',
      span: 12,
      hidden: businessFullTime !== 'OTHER' || jobDescriptionState !== 'พนักงานเงินเดือน' || occupation === 'นักเรียน/นักศึกษา' || !occupation,
      options: optionsBusinessFullTime2 || [],
      rules: {
        required: true,
      },
      onSelect: fn.onChange,
    },
    {
      key: '11',
      name: 'businessGovernmentOfficer',
      label: 'สังกัดหน่วยงาน',
      placeholder: 'เลือกสังกัดหน่วยงาน',
      disabled: disabledField,
      type: 'SELECT_GROUP',
      span: 12,
      value: business,
      hidden: occupation === 'นักเรียน/นักศึกษา' || jobDescriptionState !== 'พนักงานราชการ' || !occupation,
      options: optionsBusinessGovernment || [],
      rules: {
        required: true,
      },
      onSelect: fn.onChange,
    },
    {
      key: '11',
      name: 'businessPartTime',
      label: 'ลักษณะงานที่ทำ',
      placeholder: 'เลือกลักษณะงานที่ทำ',
      disabled: disabledField,
      type: 'SELECT_GROUP',
      span: 12,
      value: business,
      hidden: occupation === 'นักเรียน/นักศึกษา' || jobDescriptionState !== 'พนักงานชั่วคราว/พนักงานอิสระ' || !occupation,
      options: optionsBusinessPartTime || [],
      rules: {
        required: true,
      },
      onSelect: fn.onChange,
    },
    {
      key: '11',
      name: 'businessOwn',
      label: 'ลักษณะธุรกิจ',
      placeholder: 'เลือกลักษณะธุรกิจ',
      disabled: disabledField,
      type: 'SELECT_GROUP',
      span: 12,
      value: business,
      hidden: occupation === 'นักเรียน/นักศึกษา' || jobDescriptionState !== 'เจ้าของกิจการ' || !occupation,
      options: optionsBusinessOwn || [],
      rules: {
        required: true,
      },
      onSelect: fn.onChange,
    },
    // {
    //   key: '11',
    //   name: 'business',
    //   label: 'ลักษณะธุรกิจ',
    //   placeholder: 'เลือกลักษณะธุรกิจ',
    //   disabled: disabledField,
    //   type: 'SELECT',
    //   span: 12,
    //   hidden: occupation === 'นักเรียน/นักศึกษา' || !occupation,
    //   options: optionsBusiness || [],
    //   rules: {
    //     required: true,
    //   },
    //   onSelect: fn.onChange,
    // },
    {
      key: '12',
      name: 'companyNameFullTime',
      label: 'ชื่อบริษัท',
      placeholder: 'กรอกชื่อบริษัท',
      disabled: disabledField,
      type: 'INPUT',
      span: 12,
      hidden: occupation === 'นักเรียน/นักศึกษา' || jobDescriptionState !== 'พนักงานเงินเดือน' || !occupation,
      rules: {
        required: true,
      },
    },
    {
      key: '12',
      name: 'companyNameGovernmentOfficer',
      label: 'ชื่อหน่วยงาน',
      placeholder: 'กรอกชื่อหน่วยงาน',
      disabled: disabledField,
      type: 'INPUT',
      span: 12,
      hidden: occupation === 'นักเรียน/นักศึกษา' || jobDescriptionState !== 'พนักงานราชการ' || !occupation,
      rules: {
        required: true,
      },
    },
    {
      key: '12',
      name: 'companyNamePartTime',
      label: 'ชื่อร้านที่ทำงาน/บริษัทที่จ้าง',
      placeholder: 'กรอกชื่อร้านที่ทำงาน/บริษัทที่จ้าง',
      disabled: disabledField,
      type: 'INPUT',
      span: 12,
      hidden: occupation === 'นักเรียน/นักศึกษา' || jobDescriptionState !== 'พนักงานชั่วคราว/พนักงานอิสระ' || !occupation,
      rules: {
        required: true,
      },
    },
    {
      key: '12',
      name: 'companyNameOwn',
      label: 'ชื่อกิจการ/ร้านค้า* ',
      placeholder: 'กรอกชื่อกิจการ/ร้านค้า',
      disabled: disabledField,
      type: 'INPUT',
      span: 12,
      hidden: occupation === 'นักเรียน/นักศึกษา' || jobDescriptionState !== 'เจ้าของกิจการ' || !occupation,
      rules: {
        required: true,
      },
    },
    // {
    //   key: '12',
    //   name: 'companyName',
    //   label: 'ชื่อบริษัท',
    //   placeholder: 'กรอกชื่อบริษัท',
    //   disabled: disabledField,
    //   type: 'INPUT',
    //   span: 12,
    //   hidden: occupation === 'นักเรียน/นักศึกษา' || !occupation,
    //   rules: {
    //     required: true,
    //   },
    // },
    {
      key: '13',
      name: 'companyLocationFullTime',
      label: 'ที่อยู่ที่ทำงาน',
      placeholder: 'กรอกที่อยู่ที่ทำงาน',
      disabled: disabledField,
      type: 'INPUT',
      span: 12,
      hidden: occupation === 'นักเรียน/นักศึกษา' || jobDescriptionState !== 'พนักงานเงินเดือน' || !occupation,
      rules: {
        required: true,
      },
    },
    {
      key: '13',
      name: 'companyLocationGovernmentOfficer',
      label: 'ที่อยู่หน่วยงาน',
      placeholder: 'กรอกที่อยู่หน่วยงาน',
      disabled: disabledField,
      type: 'INPUT',
      span: 12,
      hidden: occupation === 'นักเรียน/นักศึกษา' || jobDescriptionState !== 'พนักงานราชการ' || !occupation,
      rules: {
        required: true,
      },
    },
    {
      key: '13',
      name: 'companyLocationPartTime',
      label: 'ที่อยู่ร้านที่ทำงาน/บริษัทที่จ้าง',
      placeholder: 'กรอกที่อยู่ร้านที่ทำงาน/บริษัทที่จ้าง',
      disabled: disabledField,
      type: 'INPUT',
      span: 12,
      hidden: occupation === 'นักเรียน/นักศึกษา' || jobDescriptionState !== 'พนักงานชั่วคราว/พนักงานอิสระ' || !occupation,
      rules: {
        required: true,
      },
    },
    {
      key: '13',
      name: 'companyLocationOwn',
      label: 'ที่อยู่กิจการ/ร้านค้า',
      placeholder: 'กรอกที่อยู่กิจการ/ร้านค้า',
      disabled: disabledField,
      type: 'INPUT',
      span: 12,
      hidden: occupation === 'นักเรียน/นักศึกษา' || jobDescriptionState !== 'เจ้าของกิจการ' || !occupation,
      rules: {
        required: true,
      },
    },
    // {
    //   key: '13',
    //   name: 'companyLocation',
    //   label: 'ที่อยู่ที่ทำงาน',
    //   placeholder: 'กรอกที่อยู่ที่ทำงาน',
    //   disabled: disabledField,
    //   type: 'INPUT',
    //   span: 12,
    //   hidden: occupation === 'นักเรียน/นักศึกษา' || !occupation,
    //   rules: {
    //     required: true,
    //   },
    // },
    {
      key: '14',
      name: 'position',
      label: 'ตำแหน่งงาน',
      placeholder: 'กรอกตำแหน่งงาน',
      disabled: disabledField,
      type: 'INPUT',
      span: 6,
      hidden: occupation === 'นักเรียน/นักศึกษา' || !jobDescriptionState || !occupation,
      rules: {
        required: true,
      },
    },
    {
      key: '15',
      name: 'experience',
      label: 'อายุงาน',
      placeholder: 'เลือกอายุงาน',
      disabled: disabledField,
      type: 'SELECT',
      span: 6,
      hidden: occupation === 'นักเรียน/นักศึกษา' || !jobDescriptionState || !occupation,
      options: optionsExperience || [],
      rules: {
        required: true,
      },
      onSelect: fn.onChange,
    },
    {
      key: '16',
      name: 'salary',
      label: 'รายได้ต่อเดือน',
      placeholder: 'กรอกรายได้ต่อเดือน',
      disabled: disabledField,
      type: 'SELECT',
      options: optionAvgIncome || [],
      span: 6,
      hidden: occupation === 'นักเรียน/นักศึกษา' || !jobDescriptionState || !occupation,
      rules: {
        required: true,
        pattern: {
          value: /^[0-9]+$/,
          message: 'ต้องเป็นตัวเลขเท่านั้น',
        },
      },
      onSelect: fn.onChange,
    },
    {
      key: '17',
      name: 'companyPhoneNumber',
      label: jobDescriptionState === 'เจ้าของกิจการ' ? 'เบอร์ติดต่อกิจการ/ร้านค้า' : 'เบอร์ที่ทำงาน',
      placeholder: jobDescriptionState === 'เจ้าของกิจการ' ? 'กรอกเบอร์ติดต่อกิจการ/ร้านค้า' : 'กรอกเบอร์ที่ทำงาน',
      disabled: disabledField,
      type: 'INPUT',
      span: 6,
      hidden: occupation === 'นักเรียน/นักศึกษา' || !jobDescriptionState || !occupation,
      rules: {
        required: true,
      },
    },
    {
      key: '18',
      name: 'incomeDoc',
      label: 'เอกสารรายได้',
      disabled: disabledField,
      type: 'RADIO',
      span: incomeDoc === 'NO' ? 18 : 12,
      hidden: conditionUploadFile,
      options: optionsDoc,
      defaultValue: incomeDoc,
      onChangeRadio: fn.onChange,
      rules: {
        required: true,
      },
    },
    {
      key: '18',
      name: 'proveOwnBusinessImageFlag',
      label: 'เอกสารกิจการ',
      disabled: disabledField,
      type: 'RADIO',
      span: 12,
      hidden:
        jobDescriptionState !== 'เจ้าของกิจการ' ||
        (typeInstallment === 'REFINANCE' && occupation === 'บุคคลทั่วไป' && jobDescriptionState !== 'เจ้าของกิจการ') ||
        occupation === 'นักเรียน/นักศึกษา' ||
        !occupation,
      options: optionBusinessDoc,
      defaultValue: proveOwnBusinessImageFlag,
      onChangeRadio: fn.onChange,
      // remark: typeInstallment === 'REFINANCE' ? '(กรณีใบจดทะเบียนหรือเอกสารแสดงความเป็นเจ้าของกิจการเป็นบุคคลในเครือญาติ โปรดใส่ชื่อบุคคลนั้นลงผู้ติดต่อ)' : '',
      // rules: {
      //   required: true
      // },
    },
    {
      key: '18',
      name: 'salesChannels',
      label: 'ช่องทางการขาย',
      disabled: disabledField,
      type: 'RADIO',
      span: 12,
      hidden:
        jobDescriptionState !== 'เจ้าของกิจการ' ||
        (typeInstallment === 'REFINANCE' && occupation === 'บุคคลทั่วไป' && jobDescriptionState !== 'เจ้าของกิจการ') ||
        occupation === 'นักเรียน/นักศึกษา' ||
        !occupation,
      options: optionSaleChannel,
      defaultValue: saleChannel,
      onChangeRadio: fn.onChange,
      // rules: {
      //   required: (typeInstallment === 'REFINANCE' && jobDescriptionState === 'เจ้าของกิจการ') ? true : false
      // },
    },
    {
      key: '22',
      name: 'registeredBusinessDocImage',
      label: 'แนบใบจดทะเบียนการค้า / ใบอนุญาติ / สัญญาเช่า หรือ หลักฐานใดที่แสดงความเป็นเจ้าของ',
      type: 'FILE_UPLOAD_V2',
      hidden:
        jobDescriptionState !== 'เจ้าของกิจการ' ||
        (typeInstallment === 'INSTALLMENT' && occupation === 'บุคคลทั่วไป' && jobDescriptionState === 'เจ้าของกิจการ') ||
        (typeInstallment === 'REFINANCE' && occupation === 'บุคคลทั่วไป' && proveOwnBusinessImageFlag !== 'REGISTERED_BUSINESS') ||
        occupation === 'นักเรียน/นักศึกษา' ||
        visibleBusinessDocField ||
        !jobDescriptionState ||
        !occupation,
      span: 12,
      spanMd: 24,
      maxCount: 6,
      disabled: disabledField,
      bucket: `${GCS_BUCKET_REGISTER}`,
      subDir: `${GCS_PATH_REGISTER}`,
      defaultFileList: registeredBusinessDocImage,
      handleChange: (e) => fn.onChangeUploadImage(e, 'registeredBusinessDocImage'),
      setLoading: fn.setLoadingUpload,
    },
    {
      key: '22',
      name: 'unRegisteredbusinessDocImage',
      label: 'แนบภาพหน้าร้าน หรือ ภาพกิจการ',
      type: 'FILE_UPLOAD_V2',
      hidden:
        jobDescriptionState !== 'เจ้าของกิจการ' ||
        (typeInstallment === 'REFINANCE' &&
          occupation === 'บุคคลทั่วไป' &&
          proveOwnBusinessImageFlag !== 'UNREGISTERED_BUSINESS' &&
          saleChannel !== 'OFFLINE') ||
        visibleBusinessDocField ||
        !jobDescriptionState ||
        occupation === 'นักเรียน/นักศึกษา' ||
        !occupation,
      span: 12,
      spanMd: 24,
      maxCount: 6,
      disabled: disabledField,
      bucket: `${GCS_BUCKET_REGISTER}`,
      subDir: `${GCS_PATH_REGISTER}`,
      defaultFileList: unRegisteredbusinessDocImage && unRegisteredbusinessDocImage.length !== 0 ? unRegisteredbusinessDocImage : [],
      handleChange: (e) => fn.onChangeUploadImage(e, 'unRegisteredbusinessDocImage'),
      setLoading: fn.setLoadingUpload,
    },
    {
      key: '19',
      name: 'urlOnlineSale',
      label:
        typeInstallment === 'INSTALLMENT' && jobDescriptionState === 'เจ้าของกิจการ' ? 'ลิงก์เว็บไซต์ของกิจการ (ถ้ามี)' : 'ลิงก์เว็บไซต์ของกิจการ',
      placeholder: 'กรอกลิงก์ ร้านค้าหรือช่องทางการขาย',
      disabled: disabledField,
      type: 'INPUT',
      span: 12,
      hidden:
        jobDescriptionState !== 'เจ้าของกิจการ' ||
        (typeInstallment === 'REFINANCE' && occupation === 'บุคคลทั่วไป' && jobDescriptionState === 'เจ้าของกิจการ' && saleChannel !== 'ONLINE') ||
        occupation === 'นักเรียน/นักศึกษา' ||
        visibleBusinessDocField ||
        !jobDescriptionState ||
        !occupation,
      // rules: {
      //   required: (typeInstallment === 'REFINANCE' && jobDescriptionState === 'เจ้าของกิจการ') ? true : false
      // },
    },
    {
      key: '22',
      name: 'productShopSaleImage',
      label: 'แนบภาพสินค้า หรือภาพการบริการ',
      type: 'FILE_UPLOAD_V2',
      hidden:
        jobDescriptionState !== 'เจ้าของกิจการ' ||
        (typeInstallment === 'INSTALLMENT' && occupation === 'บุคคลทั่วไป' && jobDescriptionState === 'เจ้าของกิจการ') ||
        (typeInstallment === 'REFINANCE' &&
          occupation === 'บุคคลทั่วไป' &&
          jobDescriptionState === 'เจ้าของกิจการ' &&
          proveOwnBusinessImageFlag === 'UNREGISTERED_BUSINESS' &&
          saleChannel !== 'OFFLINE') ||
        (typeInstallment === 'REFINANCE' &&
          occupation === 'บุคคลทั่วไป' &&
          jobDescriptionState === 'เจ้าของกิจการ' &&
          proveOwnBusinessImageFlag === 'REGISTERED_BUSINESS') ||
        occupation === 'นักเรียน/นักศึกษา' ||
        visibleBusinessDocField ||
        !jobDescriptionState ||
        !occupation,
      span: 12,
      spanMd: 24,
      maxCount: 6,
      disabled: disabledField,
      bucket: `${GCS_BUCKET_REGISTER}`,
      subDir: `${GCS_PATH_REGISTER}`,
      defaultFileList: productShopSaleImage,
      handleChange: (e) => fn.onChangeUploadImage(e, 'productShopSaleImage'),
      setLoading: fn.setLoadingUpload,
    },
    {
      key: '22',
      name: `statementImage`,
      label: `เอกสารรายได้ ${label3Month}`,
      type: 'FILE_UPLOAD_V2',
      hidden: !conditionUploadFile || incomeDoc === 'NO' || !incomeDoc || occupation === 'นักเรียน/นักศึกษา' || !occupation,
      span: 12,
      spanMd: 12,
      maxCount: 6,
      disabled: disabledField,
      rules: {
        required: true,
      },
      bucket: `${GCS_BUCKET_REGISTER}`,
      subDir: `${GCS_PATH_REGISTER}`,
      defaultFileList: fileListStatement,
      handleChange: (e) => fn.onChangeUploadImage(e, 'statementImage'),
      setLoading: fn.setLoadingUpload,
    },
    {
      key: '19',
      name: 'filePassword',
      label: 'รหัสเข้าไฟล์',
      placeholder: 'กรอกรหัสเข้าไฟล์',
      disabled: disabledField,
      type: 'INPUT',
      // span: (occupation === 'นักเรียน/นักศึกษา' || jobDescriptionState === 'พนักงานราชการ' || jobDescriptionState === 'พนักงานเงินเดือน') ? 6 : (jobDescriptionState === 'พนักงานชั่วคราว/พนักงานอิสระ' || jobDescriptionState === 'เจ้าของกิจการ') && incomeDoc === '3MONTH' ? 6 : 6,
      span: 6,
      hidden: incomeDoc === 'NO' || !incomeDoc || !occupation,
      rules: {
        required:
          (occupation === 'นักเรียน/นักศึกษา' && incomeDoc === '3MONTH') || (occupation === 'บุคคลทั่วไป' && incomeDoc === '3MONTH') ? true : false,
      },
    },
    {
      key: '20',
      name: 'customer_grade',
      label: 'เกรดลูกค้า',
      placeholder: 'เลือกเกรดลูกค้า',
      disabled: disabledField,
      type: 'SELECT',
      // span: incomeDoc === 'NO' ? 6 : (occupation === 'นักเรียน/นักศึกษา' || jobDescriptionState === 'พนักงานราชการ' || jobDescriptionState === 'พนักงานเงินเดือน') ? 6 : (jobDescriptionState === 'พนักงานชั่วคราว/พนักงานอิสระ' || jobDescriptionState === 'เจ้าของกิจการ') && incomeDoc === '3MONTH' ? 6 : 6,
      span: 6,
      options:
        [
          {label: '', value: '-'},
          {label: 'A (0-5)', value: 'A'},
          {label: 'B (6-15)', value: 'B'},
          {label: 'C (16-59)', value: 'C'},
          {label: 'D (60+)', value: 'D'},
          {label: '? (ยังไม่ระบุ)', value: '?'},
          {label: 'V (VIP)', value: 'V'},
          {label: 'X (Blocked)', value: 'X'},
        ] || [],
      // hidden: incomeDoc === 'NO' || !incomeDoc || !occupation,
      rules: {
        required: true,
      },
    },
    {
      key: '22',
      name: 'statementImage',
      label: 'แนบไฟล์เอกสาร',
      type: 'FILE_UPLOAD_V2',
      hidden: conditionUploadFile,
      span: 24,
      spanMd: 24,
      maxCount: 6,
      disabled: disabledField,
      rules: {
        required: true,
      },
      bucket: `${GCS_BUCKET_REGISTER}`,
      subDir: `${GCS_PATH_REGISTER}`,
      defaultFileList: fileListStatement,
      handleChange: (e) => fn.onChangeUploadImage(e, 'statementImage'),
      setLoading: fn.setLoadingUpload,
    },
    {
      key: '22',
      name: 'governmentOfficial',
      label: 'แนบภาพบัตรประจำตัวพนักงานราชการ',
      type: 'FILE_UPLOAD_V2',
      hidden: jobDescriptionState !== 'พนักงานราชการ' || occupation === 'นักเรียน/นักศึกษา' || !occupation,
      span: 24,
      spanMd: 24,
      maxCount: 6,
      disabled: disabledField,
      bucket: `${GCS_BUCKET_REGISTER}`,
      subDir: `${GCS_PATH_REGISTER}`,
      defaultFileList: fileListGovernmentCardImage,
      handleChange: (e) => fn.onChangeUploadImage(e, 'governmentOfficial'),
      setLoading: fn.setLoadingUpload,
    },
    {
      key: '22',
      name: 'proveWork',
      label: 'แนบภาพการทำงาน 2-3 ภาพ หรือเอกสารหลักฐานใดที่ยืนยันการทำงาน',
      type: 'FILE_UPLOAD_V2',
      hidden:
        occupation === 'นักเรียน/นักศึกษา' || business === 'ไรเดอร์/แมสเซ็นเจอร์/แท็กซี่' || jobDescriptionState !== 'พนักงานชั่วคราว/พนักงานอิสระ',
      span: 24,
      spanMd: 24,
      maxCount: 6,
      urlUpload: `${API_URL}${UPLOAD_FILE}`,
      onUpload: fn.onChangeUploadImage,
      disabled: disabledField,
      bucket: `${GCS_BUCKET_REGISTER}`,
      subDir: `${GCS_PATH_REGISTER}`,
      defaultFileList: proveWork,
      handleChange: (e) => fn.onChangeUploadImage(e, 'proveWork'),
      setLoading: fn.setLoadingUpload,
    },
    {
      key: '22',
      name: 'riderAppication',
      label: 'แนบภาพหน้าแอปพลิเคชั่นไรเดอร์ (หน้าชื่อ-นามสกุลลูกค้า) และหน้าสรุปยอดการทำงานล่าสุด',
      type: 'FILE_UPLOAD_V2',
      hidden:
        occupation === 'นักเรียน/นักศึกษา' || business !== 'ไรเดอร์/แมสเซ็นเจอร์/แท็กซี่' || jobDescriptionState !== 'พนักงานชั่วคราว/พนักงานอิสระ',
      span: 24,
      spanMd: 24,
      maxCount: 6,
      disabled: disabledField,
      bucket: `${GCS_BUCKET_REGISTER}`,
      subDir: `${GCS_PATH_REGISTER}`,
      defaultFileList: riderAppicationImage,
      handleChange: (e) => fn.onChangeUploadImage(e, 'riderAppication'),
      setLoading: fn.setLoadingUpload,
    },
  ];
};

export const FORM_PERSONAL_CONTACT = ({options, disabledField, data}) => {
  const {optionRelationship} = options;
  const {typeInstallment} = data;

  return [
    {
      title: 'ผู้ติดต่อฉุกเฉินคนที่ 1',
      data: [
        {
          key: '1',
          name: 'relationship1',
          label: 'ความสัมพันธ์',
          type: 'SELECT',
          placeholder: 'เลือกความสัมพันธ์',
          disabled: disabledField,
          span: 6,
          options: optionRelationship || [],
          rules: {
            required: true,
          },
        },
        {
          key: '2',
          name: 'phoneNumber1',
          label: 'เบอร์โทรศัพท์',
          placeholder: 'กรอกเบอร์โทรศัพท์',
          disabled: disabledField,
          type: 'INPUT',
          span: 6,
          rules: {
            required: true,
            maxLength: {
              value: 10,
              message: 'ระบุจำนวน 10 ตัวอักษร',
            },
            minLength: {
              value: 10,
              message: 'ระบุจำนวน 10 ตัวอักษร',
            },
            pattern: {
              value: /^[0-9]+$/,
              message: 'ต้องเป็นตัวเลขเท่านั้น',
            },
          },
        },
        {
          key: '3',
          name: 'firstName1',
          label: 'ชื่อ',
          placeholder: 'กรอกชื่อ',
          disabled: disabledField,
          type: 'INPUT',
          span: 6,
          rules: {
            required: true,
          },
        },
        {
          key: '4',
          name: 'lastName1',
          label: 'นามสกุล',
          placeholder: 'กรอกนามสกุล',
          disabled: disabledField,
          type: 'INPUT',
          span: 6,
          rules: {
            required: true,
          },
        },
      ],
    },
    {
      title: 'ผู้ติดต่อฉุกเฉินคนที่ 2',
      data: [
        {
          key: '1',
          name: 'relationship2',
          label: 'ความสัมพันธ์',
          type: 'SELECT',
          placeholder: 'เลือกความสัมพันธ์',
          disabled: disabledField,
          span: 6,
          options: optionRelationship || [],
          // rules: {
          //   required: typeInstallment === 'INSTALLMENT' ? false : true,
          // },
        },
        {
          key: '2',
          name: 'phoneNumber2',
          label: 'เบอร์โทรศัพท์',
          placeholder: 'กรอกเบอร์โทรศัพท์',
          disabled: disabledField,
          type: 'INPUT',
          span: 6,
          rules: {
            // required: typeInstallment === 'INSTALLMENT' ? false : true,
            maxLength: {
              value: 10,
              message: 'ระบุจำนวน 10 ตัวอักษร',
            },
            minLength: {
              value: 10,
              message: 'ระบุจำนวน 10 ตัวอักษร',
            },
            pattern: {
              value: /^[0-9]+$/,
              message: 'ต้องเป็นตัวเลขเท่านั้น',
            },
          },
        },
        {
          key: '3',
          name: 'firstName2',
          label: 'ชื่อ',
          placeholder: 'กรอกชื่อ',
          disabled: disabledField,
          type: 'INPUT',
          span: 6,
          // rules: {
          //   required: typeInstallment === 'INSTALLMENT' ? false : true,
          // },
        },
        {
          key: '4',
          name: 'lastName2',
          label: 'นามสกุล',
          placeholder: 'กรอกนามสกุล',
          disabled: disabledField,
          type: 'INPUT',
          span: 6,
          // rules: {
          //   required: typeInstallment === 'INSTALLMENT' ? false : true,
          // },
        },
      ],
    },
    {
      title: 'ผู้ติดต่อฉุกเฉินคนที่ 3',
      data: [
        {
          key: '1',
          name: 'relationship3',
          label: 'ความสัมพันธ์',
          type: 'SELECT',
          placeholder: 'เลือกความสัมพันธ์',
          disabled: disabledField,
          span: 6,
          options: optionRelationship || [],
          // rules: {
          //   required: typeInstallment === 'INSTALLMENT' ? false : true,
          // },
        },
        {
          key: '2',
          name: 'phoneNumber3',
          label: 'เบอร์โทรศัพท์',
          placeholder: 'กรอกเบอร์โทรศัพท์',
          disabled: disabledField,
          type: 'INPUT',
          span: 6,
          rules: {
            // required: typeInstallment === 'INSTALLMENT' ? false : true,
            maxLength: {
              value: 10,
              message: 'ระบุจำนวน 10 ตัวอักษร',
            },
            minLength: {
              value: 10,
              message: 'ระบุจำนวน 10 ตัวอักษร',
            },
            pattern: {
              value: /^[0-9]+$/,
              message: 'ต้องเป็นตัวเลขเท่านั้น',
            },
          },
        },
        {
          key: '3',
          name: 'firstName3',
          label: 'ชื่อ',
          placeholder: 'กรอกชื่อ',
          disabled: disabledField,
          type: 'INPUT',
          span: 6,
          // rules: {
          //   required: typeInstallment === 'INSTALLMENT' ? false : true,
          // },
        },
        {
          key: '4',
          name: 'lastName3',
          label: 'นามสกุล',
          placeholder: 'กรอกนามสกุล',
          disabled: disabledField,
          type: 'INPUT',
          span: 6,
          // rules: {
          //   required: typeInstallment === 'INSTALLMENT' ? false : true,
          // },
        },
      ],
    },
  ];
};

export const FORM_CONSIDER = ({options, disabledField, data, fn}) => {
  const {occupation, isApproveSpecial, dataAscore, statusAppDefault, fgfData, walkin, acceptConditions, perAction, typeInstallment, defaultTypeLock} = data;
  const {optionsTypeLockInstallment, optionsTypeLockRefinance} = options

  return [
    {
      key: '1',
      name: 'knownUfriendFrom',
      label: 'รู้จัก Ufriend จาก:',
      placeholder: '',
      disabled: true,
      oneLine: true,
      component: 'TEXT_FORM',
      textStyle: {
        text: !!data.knownUfriend ? data.knownUfriend.label : 'ไม่พบข้อมูล',
        bold: false,
      },
      span: 24,
    },
    {
      // FGF: หน้าพิจารณา เพิ่มช่องรหัสและผลสรุปว่าได้สิทธิไหม
      key: '2',
      name: 'fgf_code',
      label: 'รหัสผู้แนะนำ:',
      placeholder: '',
      disabled: true,
      oneLine: true,
      component: 'TEXT_FORM',
      textStyle: {
        text: fgfData?.code ? fgfData.code : '',
        bold: false,
      },
      span: 24,
    },
    {
      // FGF: หน้าพิจารณา เพิ่มช่องรหัสและผลสรุปว่าได้สิทธิไหม
      key: '3',
      name: 'privilege_text',
      disabled: true,
      iconText: true,
      component: 'ICON_TEXT_FORM',
      iconProps: {
        icon: fgfData?.privilege_status === true ? 'CHECK' : fgfData?.privilege_status === false ? 'CLOSE' : '',
        iconSpan: 1,
      },
      textStyle: {
        text: fgfData?.privilege_text ? fgfData.privilege_text : '',
        bold: false,
        textSpan: 22,
      },
      span: 24,
      hidden: _.isEmpty(fgfData) ? true : false,
    },
    {
      key: '1',
      name: 'acceptCondition',
      label: 'ยอมรับเงื่อนไข:',
      placeholder: '',
      disabled: true,
      oneLine: true,
      component: 'TEXT_FORM',
      textStyle: {
        text: acceptConditions ? 'ลูกค้ายอมรับเงื่อนไขลูกค้าต้องอายุ 20 - 60 ปี ณ วันที่ทำสัญญา' : '',
        bold: false,
        color: 'red',
      },
      span: 24,
    },
    {
      key: '4',
      span: 24,
      name: 'othersDetails',
      label: 'หมายเหตุ(จากลูกค้า)',
      type: 'INPUT_AREA',
      disabled: true,
      rows: 2,
    },
    {
      key: '5',
      span: 24,
      name: 'remark',
      label: 'หมายเหตุ(สำหรับเจ้าหน้าที่)',
      type: 'INPUT_AREA',
      disabled: disabledField,
      maxLength: 200,
      rows: 3,
    },
    // {
    //   key: '7',
    //   name: 'walkin',
    //   label: 'ลูกค้าลงทะเบียน:',
    //   span: 24,
    //   // type: 'CHECKBOX_DEFAULT',
    //   type: walkin === true ? 'CHECKBOX_DEFAULT' : '',
    //   disabled: disabledField,
    //   options: [{value: true, label: 'Walk in'}],
    //   // options: [{ value: 'Pencil', label: 'Pencil' }],
    //   onChangeCustom: fn.onChange,
    //   oneLine: walkin === true ? false : true,
    //   component: 'TEXT_FORM',
    //   textStyle: {
    //     text: walkin ? '' : '-',
    //     bold: false,
    //   },
    // },
    {
      key: '7',
      name: 'walkin',
      label: 'ลูกค้าลงทะเบียน:',
      span: 24,
      type: 'CHECKBOX_DEFAULT',
      disabled: disabledField,
      options: [{ value: true, label: 'Walk in' }],
      hidden: !perAction?.includes('edit'),
      onChangeCustom: fn.onChange,
    },
    {
      key: '6',
      span: 24,
      name: 'is_approve_special',
      label: '',
      type: 'CHECKBOX',
      disabled: disabledField,
      options: [{value: true, label: 'อนุมัติพิเศษ'}],
      onChangeCustom: fn.onChange,
    },
    {
      key: '7',
      span: 24,
      name: 'remark_approve_special',
      label: 'เหตุผลในการอนุมัติพิเศษ',
      type: 'INPUT_AREA',
      disabled: disabledField,
      rows: 1,
      maxLength: 200,
      rules: {
        required: true,
      },
      hidden: isApproveSpecial ? false : true,
    },
    {
      key: '8',
      span: 24,
      name: 'remark_consider',
      label: 'หมายเหตุสำหรับลูกค้า chatcone',
      type: 'INPUT_AREA',
      disabled: disabledField,
      maxLength: 200,
      rows: 3,
      rules: {
        required: false,
      },
    },
    {
      key: '9',
      span: 24,
      name: 'salary_avg',
      label: 'รายได้เฉลี่ย',
      placeholder: 'กรอกรายได้เฉลี่ย',
      disabled: disabledField,
      type: 'INPUT_NUMBER',
      rows: 2,
      onChangeCustom: fn.onChange,
    },
    {
      key: '10',
      span: 24,
      label: '',
      name: 'local_scale',
      value: 'A Score = ' + dataAscore.aLocalScale || 'ไม่สามารถคำนวณได้',
      component: 'TAG',
      color: 'blue',
      hidden: occupation !== 'บุคคลทั่วไป' ? true : false,
    },
    {
      key: '11',
      span: 24,
      name: 'typeLock',
      label: 'รูปแบบการล็อคเครื่อง',
      type: 'RADIO',
      placeholder: 'เลือกรูปแบบการล็อคเครื่อง',
      disabled: disabledField,
      defaultValue: defaultTypeLock,
      options: typeInstallment === 'INSTALLMENT' ? optionsTypeLockInstallment : optionsTypeLockRefinance,
    },
    {
      key: '12',
      span: 24,
      name: 'statusApp',
      disabled: disabledField,
      defaultValue: data.statusAppDefault,
      label: '',
      type: 'RADIO',
      options: options.statusApprove,
      onChangeRadio: fn.onChange,
    },
    {
      key: '13',
      span: 24,
      name: 'remark_reject',
      label: 'เหตุผลที่ไม่อนุมัติ',
      type: 'INPUT_AREA',
      disabled: disabledField,
      rows: 1,
      rules: {
        required: true,
      },
      maxLength: 200,
      hidden: statusAppDefault === 'REJECTED' ? false : true,
    },
  ];
};

export const FORM_APPOINTMENT = ({options, defaultValue, fn}) => {
  const {optionBranch} = options;

  return [
    {
      key: '1',
      span: 8,
      name: 'fullName',
      label: 'ชื่อนามสกุล',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '2',
      span: 8,
      name: 'phoneNumber',
      label: 'เบอร์โทรศัพท์',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '3',
      span: 8,
      name: 'branch',
      label: 'สาขา',
      type: 'SELECT_MATCH_SELECT_WIDTH',
      placeholder: 'เลือกสาขา',
      options: optionBranch || [],
      rules: {
        required: true,
      },
    },
    {
      key: '4',
      span: 7,
      name: 'startDate',
      label: 'เริ่มต้น :',
      type: 'DATE_PICKER',
      format: 'DD/MM/YYYY',
      disabledDate: (d) => !d || d.isSameOrBefore(moment().add(-1, 'days')),
      placeholder: 'เลือกวันเริ่มต้น',
      onChange: fn.onChange,
      rules: {
        required: true,
      },
    },
    {
      key: '5',
      span: 5,
      name: 'startTime',
      label: 'เวลา :',
      type: 'TIME_PICKER',
      format: 'HH:mm',
      placeholder: 'เวลา',
      onChange: fn.onChange,
      defaultValue: defaultValue.startTime ? moment(defaultValue.startTime, 'HH:mm') : null,
      rules: {
        required: true,
      },
    },
    {
      key: '6',
      span: 7,
      name: 'endDate',
      label: 'สิ้นสุด :',
      type: 'DATE_PICKER',
      format: 'DD/MM/YYYY',
      disabledDate: (d) => !d || d.isSameOrBefore(moment().add(-1, 'days')),

      placeholder: 'เลือกวันสิ้นสุด',
      rules: {
        required: true,
      },
    },
    {
      key: '7',
      span: 5,
      name: 'endTime',
      label: 'เวลา :',
      type: 'TIME_PICKER',
      format: 'HH:mm',
      placeholder: 'เวลา',
      defaultValue: defaultValue.endTime ? moment(defaultValue.endTime, 'HH:mm') : null,
      rules: {
        required: true,
      },
    },
    {
      key: '8',
      span: 24,
      name: 'remark_appointment',
      label: 'หมายเหตุ(สำหรับลูกค้า + chatcone)',
      labelRed: '*แสดงบนใบนัดแคปให้ลูกค้า',
      type: 'INPUT_AREA',
      rows: 2,
      rules: {
        maxLength: 500,
      },
    },
    {
      key: '9',
      span: 24,
      name: 'remark',
      label: 'หมายเหตุ(สำหรับเจ้าหน้าที่)',
      type: 'INPUT_AREA',
      disabled: true,
      maxLength: 200,
      rows: 2,
    },
    {
      key: '10',
      span: 24,
      name: 'show_installment',
      label: 'แสดงยอดผ่อนต่อเดือน',
      type: 'RADIO',
      options: [
        {label: 'แสดง', value: 'SHOW'},
        {label: 'ไม่แสดง', value: 'NOT_SHOW'},
      ],
      defaultValue: defaultValue.show_installment || 'SHOW',
      onChangeRadio: fn.onChange,
      rules: {
        required: true,
      },
    },
  ];
};

export const EditRemarkConsider = () => {
  return [
    {
      key: '1',
      span: 24,
      name: 'remark',
      label: 'หมายเหตุ',
      type: 'INPUT_AREA',
      maxLength: 200,
      rows: 3,
      rules: {
        required: true,
      },
    },
  ];
};
