import { STORAGE_API, handleApiError } from './utils';

export const uploadFile = async (formData) => {
  try {
    const res = await STORAGE_API.post('/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return res.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const checkOnline = async () => {
  try {
    const res = await STORAGE_API.get('/health');
    return res.data;
  } catch (error) {
    return handleApiError(error);
  }
};


