import styled from 'styled-components';
import ColorSchema from '../../../styles/colors';

export const UploadStyled = styled.div`
  .ant-upload-list-item-info {
    margin-left: 0px;

    .ant-upload-list-item-name {
      padding: 0 0px 0 8px;
    }
  }

  .offline {
    background: ${ColorSchema.offline};
    cursor: not-allowed;
  }

  .ant-upload-list-item-info {
    margin-left: ${(props) => (props.linkAlign ? props.linkAlign + 'px' : '')};

  }

  .ant-upload {
    .ant-btn {
      width: ${(props) => (props.width ? props.width + 'px' : '')};
    }
  }
`;

export const UploadInlineStyle = styled(UploadStyled)`
  .upload-file-style-inline {
    display: flex;
    align-items: center;
    gap: 8px;

    .ant-upload-list-text {
      .ant-upload-list-text-container {
        .ant-upload-list-item-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 180px;
        }
      }
    }
    .ant-upload-list-item:hover .ant-upload-list-item-info {
      background-color: unset !important;
    }
  }
`;

UploadStyled.shouldForwardProp = (prop) => !['orderLabel'].includes(prop);
UploadInlineStyle.shouldForwardProp = (prop) => !['orderLabel'].includes(prop);
